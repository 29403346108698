import React, { useContext, useEffect, useRef, createRef } from "react"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import {
  Container,
  Flex,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon
} from '@chakra-ui/react'
import { H1, H2, H3, H4 } from '../theme/Headings'
import { spacing, breakpoints, colors } from '../theme/themeAlopex'
import ColorContext from '../context/ColorContext'
import RipplingAK from '../components/RipplingAK'
import Instagram from '../components/Instagram'
// Services
// import SVGStrategy from '../images/services/strategy.inline.svg'
import Strategy from '../components/Services/Strategy'
import Content from '../components/Services/Content'
import Marketing from '../components/Services/Marketing'
import Design from '../components/Services/Design'
import Digital from '../components/Services/Digital'
// Newsletter
import Newsletter from '../components/Newsletter'
// gsap
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const ServicesPage = ({ data }) => {
  const refServices = useRef([])
  const { color } = useContext(ColorContext)

  const services = [
    {
      svg: <Strategy />,
      title: data.contentJson.strategyTitle,
      description: data.contentJson.strategyCopy,
      pageLink: data.contentJson.strategyLink
    },
    {
      svg: <Content />,
      title: data.contentJson.contentTitle,
      description: data.contentJson.contentCopy,
      pageLink: data.contentJson.contentLink
    },
    {
      svg: <Marketing />,
      title: data.contentJson.marketingTitle,
      description: data.contentJson.marketingCopy,
      pageLink: data.contentJson.marketingLink
    },
    {
      svg: <Design />,
      title: data.contentJson.designTitle,
      description: data.contentJson.designCopy,
      pageLink: data.contentJson.designLink
    },
    {
      svg: <Digital />,
      title: data.contentJson.digExpTitle,
      description: data.contentJson.digExptCopy,
      pageLink: data.contentJson.digExpLink
    },
  ]

  // Clear transform matrix from transition wrapper interfering with nested fixed header
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tlWrapper = document.querySelector('.tl-wrapper-status--entered')
      if (tlWrapper !== null) {
        tlWrapper.style.transform = null
      }
    }
  })
  
  refServices.current = services.map(() => createRef())

  useEffect(() => {
    // Fade up all service blocks
    for (let i = 0; i < services.length; i++) {
      gsap.from(refServices.current[i].current, {
        scrollTrigger: {
          trigger: refServices.current[i].current,
          start: 'top 75%',
          // marker: true
        },
        duration: 1,
        y: 50,
        opacity: 0,
      })
    }
  }, [services.length])

  const slugTitle = (slug) => {
    let pos = data.allService2024Json.nodes.map((item) => {
      return item.slug
    }).indexOf(slug)
    let slugTitle = (pos !== -1) ? data.allService2024Json.nodes[pos].title : 'Missing title'
    return slugTitle
  }
  const ogImage = !!data.contentJson.seoFields.ogImage ? `https://www.alopexid.com${data.contentJson.seoFields.ogImage.replace('/static', '')}"` : null
  return (
    <Layout>
      <SEO title={data.contentJson?.seoFields?.seoTitle} description={data.contentJson?.seoFields?.seoDesc} ogImage={ogImage} />
      {/* SERVICES */}
      <Container maxWidth={breakpoints.xl} pt={spacing.header}>
        <H1 variant="pageHeader" color={color.alpha}>We are a digital agency</H1>
        <H2 textAlign="center">We do many things, but we outshine everyone in a few.</H2>
      </Container>
      <Container maxWidth={breakpoints.lg} pb={[spacing.groups, null, spacing.section]} sx={{'--alpha': color.alpha, '--bravo': color.bravo }}>
        {services.map((svc, i) => {
          return (
            <div key={`service-${i}`}>
              <Flex ref={refServices.current[i]} mt={spacing.groups} flexDirection={['column', null, null, 'row']} alignItems="center" justifyContent="center">
                <Box sx={{svg: {maxWidth: '210px', width: '100%'}}} mr={['none', null, null, spacing.elements]} mb={[spacing.elements, null, null, 0]}>
                  {svc.svg}
                </Box>
                <Flex flexDirection="column" justifyContent="center" alignItems={['center', null, null, 'flex-start' ]}>
                  <H3 style={{marginBottom: spacing.icon}}>
                    {svc.title}
                  </H3>
                  <Box as="p" textAlign={['center', null, null, 'left']} maxWidth="510px">
                    {svc.description}
                  </Box>
                  <Box
                    sx={{
                      '.serviceLinks': {
                        color: 'rgba(0, 0, 0, 0.1)',
                        background: `-webkit-linear-gradient(right, ${color.alpha}, ${color.bravo})`,
                        WebkitBackgroundClip: 'text',
                        display: 'inline-block',
                        a: {
                          color: 'rgba(0, 0, 0, 0.2)',
                          '&:hover': {
                            color: 'rgba(0, 0, 0, 0.5)'
                          }
                        }
                      }
                    }}
                  >
                    {svc.pageLink !== null && svc.pageLink !== undefined &&
                      <Box as="p" className="serviceLinks">
                        {svc.pageLink.map((lnk, ii) => {
                          return (
                            <>
                              <AniLink paintDrip hex={color.alpha} to={`/services/${lnk}`} key={`anilink-key-${ii}`}>
                                {slugTitle(lnk)}
                              </AniLink>
                              {ii < svc.pageLink.length - 1 && ', '}
                            </>
                          )
                        })}
                      </Box>
                    }
                  </Box>
                </Flex>
              </Flex>
            </div>
          )
        })}
      </Container>
      {/* Accordion things */}
      <Box backgroundColor={color.alpha} color="#fff" px="1rem">
        <Container maxWidth={breakpoints.lg} p={[`${spacing.groups} 0`, null, `${spacing.section} 0`]}>
          <H2 textAlign="center">Our Policy</H2>
          <H4 textAlign="center" mb={spacing.elements}>What makes us different:</H4>
          <Accordion allowToggle borderLeft="1px solid white" borderRight="1px solid white" mb={spacing.elements}>
            <AccordionItem>
              <AccordionButton _focus={{boxShadow: 'none'}} _hover={{backgroundColor: 'transparent'}} backgroundColor="transparent">
                <H4 mb="0" width="100%" lineHeight="2">
                  <Box flex="1" textAlign="left" display="flex" justifyContent="space-between" alignItems="center">
                    Results focused
                    <AccordionIcon />
                  </Box>
                </H4>
              </AccordionButton>
            <AccordionPanel>
              <Box as="p">
                Here’s a nasty secret: many agencies simply don’t know if what they are doing makes sense. Even worse, TV companies don’t know how many people see commercials, newspapers don’t know how many people actually read their pages, and radio stations don’t know how many people flip the channel when a lineup of ads start.
              </Box>
              <Box as="p">
                The point of marketing is to get results and the only way to know you are getting results are numbers. Whether by using focus groups, surveys, analytics or any other scientific means, we will prove to you that every dollar you spend with us comes back to your business—with more.
              </Box>
              <Box as="p">
                If something doesn’t work, we aren’t going to spend your money there.
              </Box>
            </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton _focus={{boxShadow: 'none'}} _hover={{backgroundColor: 'transparent'}} backgroundColor="transparent">
                <H4 mb="0" width="100%" lineHeight="2">
                  <Box flex="1" textAlign="left" display="flex" justifyContent="space-between" alignItems="center">
                    Quality
                    <AccordionIcon />
                  </Box>
                </H4>
              </AccordionButton>
            <AccordionPanel>
              <Box as="p">
                Our design and marketing work is meticulous and beautiful. Our team is made up of very talented Alaskans who are hand picked out of their field. We don’t outsource and we don’t simply install templates. If you hire Alopex for Search Engine Optimization, we don’t send it out to another company, it’s done here, in our office, to our standards.
              </Box>
              <Box as="p">
                It’s important to us that our clients value the standard of our work. Marketing, like any other service, is an area where you get what you pay for.
              </Box>
            </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionButton _focus={{boxShadow: 'none'}} _hover={{backgroundColor: 'transparent'}} backgroundColor="transparent">
                <H4 mb="0" width="100%" lineHeight="2">
                  <Box flex="1" textAlign="left" display="flex" justifyContent="space-between" alignItems="center">
                    Honesty
                    <AccordionIcon />
                  </Box>
                </H4>
              </AccordionButton>
            <AccordionPanel>
              <Box as="p">
              We’ve heard the stories of how our clients were treated before they came to Alopex.
              </Box>
              <Box as="p">
                Working with Alopex, you are our partner. We will work with you in whatever capacity you need. We have time to talk to you in person, and we will never blow smoke in your eyes. It’s your business, it’s your money, and we want you to see what we are doing with it.
              </Box>
            </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <H2 textAlign="center" mb={spacing.elements}>Oh, and one more thing</H2>
          <Accordion allowToggle borderLeft="1px solid white" borderRight="1px solid white">
            <AccordionItem>
              <AccordionButton _focus={{boxShadow: 'none'}} _hover={{backgroundColor: 'transparent'}} backgroundColor="transparent">
                <H4 mb="0" width="100%" lineHeight="2">
                  <Box flex="1" textAlign="left" display="flex" justifyContent="space-between" alignItems="center">
                    Qualified
                    <AccordionIcon />
                  </Box>
                </H4>
              </AccordionButton>
            <AccordionPanel>
              <Box as="p">
                The Alopex team holds degrees and certifications in our areas of expertise. The majority of our crew is Google certified in Google Ads, Analytics, Mobile Development, and a myriad of other topics.
              </Box>
              <Box as="p">
                We’ve been building extraordinary marketing experiences for Alaskans since 2012. We’ll be happy to provide you with case studies, numbers, and references.
              </Box>
            </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Container>
      </Box>
      {/* ALASKA GROWN */}
      <Box
        backgroundColor={colors.brand.slate}
        p={[`${spacing.groups} 0`, null, `${spacing.section} 0`]}
        overflow="hidden"
        position="relative"
      >
        <RipplingAK />
        <Container maxWidth={breakpoints.lg} color={colors.brand.white}>
          <H2 mb="10px">Alaska Grown</H2>
          <H3>World class work from Palmer, Alaska</H3>
          <Box as="p" maxWidth="600px">
            Alaska is one of the most beautiful places in the world. We believe that our design and code should be beautiful as well.
            When you choose to work with Alopex, you are choosing to work with locals who inherently understand your audience.
          </Box>
          <Box as="p" maxWidth="600px" mb="0">
            We do not outsource, we do not use templates. We are Alaskan creatives and computer scientists.
          </Box>
        </Container>
      </Box>
      {/* Instagram feed slider */}
      <Container maxWidth={breakpoints.xl} position="relative" p={[`${spacing.groups} 1rem`, null, `${spacing.section} 1rem`]}>
        <H2 mb={spacing.elements}>Our Office</H2>
        <Instagram />
        <Box as="p">This building was built in the 1930s and is an original colony structure. In fact, it was the chicken hatchery!</Box>
        <Box as="p">The Alopex Interaction Design office is located 564 South Denali Street, Palmer, Alaska.</Box>
      </Container>
      <Newsletter title="Hear from us" copy={data.contentJson.newsletterCopy} origin="services" />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query aloServices {
    site {
      siteMetadata {
        title
      }
    }
    contentJson(slug: {eq: "services"}) {
      title
      newsletterCopy
      strategyTitle
      strategyCopy
      strategyLink
      contentTitle
      contentCopy
      contentLink
      marketingTitle
      marketingCopy
      marketingLink
      designTitle
      designCopy
      designLink
      digExpTitle
      digExptCopy
      digExpLink
      listOfficeImages {
        officeImage
        officeImageLink
      }
      seoFields {
        seoTitle
        seoDesc
        ogImage
      }
    }
    allService2024Json {
      nodes {
        slug
        title
      }
    }
  }
`

export default ServicesPage
