import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box } from '@chakra-ui/react'
import { H5 } from '../theme/Headings'
import ColorContext from '../context/ColorContext'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import monopolyPiece from '../images/3d_home.png'

const Instagram = () => {
  const { contentJson } = useStaticQuery(
    graphql`
      query {
        contentJson(slug: {eq: "services"}) {
          fields {
            imageRel {
              officeImage {
                childImageSharp {
                  fixed(width: 270, height:270, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
          listOfficeImages {
            officeImageLink
          }
        }
      }
    `
  )
  const { color } = useContext(ColorContext)
/*   const [ images, setImages ] = useState({
    loading: false,
    imgUrls: []
  })
  useEffect(() => {
    setImages({ loading: true })
    fetch("https://ig.instant-tokens.com/users/adf00110-f8eb-4c9b-a0b4-f31caa4546bb/instagram/17841404206214220/token?userSecret=10t1zkr8s2rn15rfadxwzg")
      .then((res) => res.json())
      .then(data => {
        // console.log('data:', data)
        setImages({ loading: false })
        fetch(`https://graph.instagram.com/me/media?fields=caption,media_url,permalink&access_token=${data.Token}`, {  method: 'GET' })
          .then((igRes) => igRes.json())
          .then(igData => {
            // console.log('data:', igData)
            let officeImgs = []
            igData.data.map((igd, z) => {
              if (igd.caption.indexOf('officesnapshot') > -1) {
                officeImgs.push(igd)
              }
            })
            setImages({ imgUrls: [...officeImgs] })
          })
      })
      // console.log(images)
  }, []) */

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    waitForAnimate: false,
    variableWidth: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <Box minHeight="300px">
      {!!contentJson?.fields?.imageRel &&
        <Box position="absolute" left="0" width="2000px" pl="1rem">
          <Slider {...settings}>
            <Box
              backgroundImage={`linear-gradient(135deg, ${color.bravo}, ${color.alpha})`}
              width="270px!important"
              height="270px"
              display="flex!important"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              mr="10px"
              sx={{
                '@keyframes floating': { 
                  '0%': { 'transform': 'translate(0,  0px)' },
                  '50%':  { 'transform': 'translate(0, 10px)' },
                  '100%': { 'transform': 'translate(0, -0px)' }
                } 
              
              }}
            >
              <Box as="img" src={monopolyPiece} alt="Alopex Office" width="150px" mb="20px" animation="floating 3s infinite ease-in-out" />
              <H5 color="#ffffff" textAlign="center" lineHeight="1.2" mb="0">564 S Denali St.<br />Palmer, Alaska</H5>
            </Box>
            {contentJson.fields.imageRel.map((img, i) => {
              return (
                <Box pr="10px" maxWidth="280px" width="100%" height="270px" key={`office-image-slider-key-${i}`}>
                  <a href={contentJson.listOfficeImages[i].officeImageLink} rel="nofollow noreferrer" target="_blank">
                    <Img fixed={img.officeImage.childImageSharp.fixed} alt="Office image" />
                  </a>
                </Box>
              )
            })}
          </Slider>
        </Box>
      }
    </Box>
  )
}

export default Instagram

/* <Box minHeight="300px">
  {!!images?.imgUrls && images.imgUrls.length > 1 &&
    <Box position="absolute" left="0" width="2000px" pl="1rem">
      <Slider {...settings}>
        <Box
          backgroundImage={`linear-gradient(135deg, ${color.bravo}, ${color.alpha})`}
          width="270px!important"
          height="270px"
          display="flex!important"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mr="10px"
          sx={{
            '@keyframes floating': { 
              '0%': { 'transform': 'translate(0,  0px)' },
              '50%':  { 'transform': 'translate(0, 10px)' },
              '100%': { 'transform': 'translate(0, -0px)' }
            } 
          
          }}
        >
          <Box as="img" src={monopolyPiece} alt="Alopex Office" width="150px" mb="20px" animation="floating 3s infinite ease-in-out" />
          <H5 color="#ffffff" textAlign="center" lineHeight="1.2" mb="0">564 S Denali St.<br />Palmer, Alaska</H5>
        </Box>
        {images.imgUrls.map((img, i) => {
          return (
            <Box pr="10px" maxWidth="270px" width="270px" height="270px">
              <a href={img.permalink} rel="nofollow noreferrer" target="_blank">
                <img src={img.media_url} alt={img.caption} />
              </a>
            </Box>
          )
        })}
    </Slider>
    </Box>
  }
</Box> */