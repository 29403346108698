import React, { useRef, useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const Content = () => {
  const refContentSVG = useRef()
  const refJewelsGlow = useRef()
  const refJewels = useRef()
  const refJewel01 = useRef()
  const refJewel02 = useRef()
  const refJewel03 = useRef()
  const refJewel04 = useRef()
  const refJewel05 = useRef()
  const refJewel06 = useRef()
  const refJewel07 = useRef()
  const refJewel08 = useRef()
  const refJewel09 = useRef()
  const refJewel10 = useRef()
  useEffect(() => {
    gsap.set(refJewelsGlow.current, {transformOrigin: '50% 50%', scale: 0.8})
    gsap.from(refJewelsGlow.current, {
      repeat: -1,
      duration: 1,
      scale: 1.4,
      yoyo: true,
    })
    // ScrollTrigger animation (jewels rising)
    gsap.set(refJewels.current, {y: -10})
    gsap.from(refJewels.current, {
      scrollTrigger: {
        trigger: refContentSVG.current,
        start: 'top 75%',
        end: 'top 25%',
        scrub: true,
        // marker: true
      },
      y: 60,
    })
  }, [])
  const handleJewel = (ref, dir = 'up') => {
    let amnt = 8
    gsap.to(ref, {
      y: dir === 'up' ? amnt * -1 : amnt
    })
  }
  return (
    <svg ref={refContentSVG} style={{overflow: 'visible'}} className="svgContent" xmlns="http://www.w3.org/2000/svg" viewBox="-1299 801 400 400">
      <defs>
        <linearGradient id="contentGradient" gradientTransform="rotate(0)">
          <stop offset="5%" stopColor="var(--alpha)"></stop>
          <stop offset="95%" stopColor="var(--bravo)"></stop>
        </linearGradient>
        <linearGradient id="contentGradientFlipped" gradientTransform="rotate(0)">
          <stop offset="5%" stopColor="var(--bravo)"></stop>
          <stop offset="95%" stopColor="var(--alpha)"></stop>
        </linearGradient>
        <radialGradient
          id="gradient_x5F_bravo_x5F_fade_1_"
          cx="-1599.1835"
          cy="1041.2"
          r="123.5818"
          gradientTransform="matrix(1.6186 0 0 -1 1489.4384 2004)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0%" stopColor="var(--bravo)"></stop>
          <stop offset="100%" stopColor="var(--bravo)" stopOpacity="0"></stop>
        </radialGradient>
      </defs>
      <polygon fill="#B3B3B3" points="-949,962.8 -1249,962.8 -1299,1002.8 -899,1002.8 "></polygon>
      <polygon fill="#F2F2F2" points="-956.5,962.8 -1241.5,962.8 -1289,1002.8 -909,1002.8 "></polygon>
      <g id="lid">
        <polygon id="lidRim" fill="#A2A4A5" points="-1249,962.8 -949,962.8 -899,882.8 -1299,882.8 "></polygon>
        <polygon id="lidUnder" fill="#CCCCCC" points="-1241.5,962.8 -956.5,962.8 -913.9,891.1 -1284.1,891.1 "></polygon>
        <path id="lidTop" fill="#CCCCCC" d="M-931,850.8h-336c-17.7,0-32,14.3-32,32l0,0h400l0,0C-899,865.1-913.3,850.8-931,850.8z"></path>
        <path id="lidHandle" fill="#A2A4A5" d="M-1057.3,871.5h-83.4l0,0c0-3.7,3-6.7,6.7-6.7h70C-1060.3,864.8-1057.3,867.8-1057.3,871.5L-1057.3,871.5z"></path>
      </g>
      <ellipse ref={refJewelsGlow} id="gradient_x5F_bravo_x5F_fade" fill="url(#gradient_x5F_bravo_x5F_fade_1_)" cx="-1099" cy="962.8" rx="200" ry="123.6"></ellipse>
      <g ref={refJewels}>
        <g id="jewel01" ref={refJewel01} onMouseEnter={() => {handleJewel(refJewel01.current)}} onMouseLeave={() => {handleJewel(refJewel01.current, 'down')}}>
          <g>
            <polygon id="colour_x5F_bravo_9_" fill="var(--bravo)" points="-1111.7,1001.8 -1126.4,971 -1126,970.3 -1121.6,970.1 -1121.1,970.3 
            -1107.9,998.1 -1107.9,998.6 -1110.9,1001.9"></polygon>
            <path fill="#FFFFFF" d="M-1121.6,970.6l13.3,27.7l-3,3.3l-14.7-30.8L-1121.6,970.6 M-1121.6,969.6l-4.4,0.2l-0.8,1.4l14.7,30.7
            l1.6,0.2l3-3.3l0.2-1.1l-13.3-27.7L-1121.6,969.6L-1121.6,969.6z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_bravo_8_" fill="var(--bravo)" points="-1065.5,977.8 -1078.7,950.1 -1078.7,949.5 -1075.7,946.2 -1074.9,946.3 
            -1060.2,977.1 -1060.6,977.8 -1065,978"></polygon>
            <path fill="#FFFFFF" d="M-1075.3,946.5l14.7,30.7l-4.4,0.2l-13.3-27.7L-1075.3,946.5 M-1076.1,945.9l-3,3.3l-0.2,1.1l13.3,27.7l1,0.6
            l4.4-0.2l0.8-1.4l-14.7-30.7L-1076.1,945.9L-1076.1,945.9z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_bravo_7_" fill="var(--bravo)" points="-1088.8,946.2 -1089.1,945.7 -1089.4,941.3 -1088.7,940.8 -1076,945.3 
            -1075.8,946.1 -1078.8,949.4 -1079.3,949.5"></polygon>
            <path fill="#FFFFFF" d="M-1088.9,941.3l12.7,4.5l-3,3.3l-9.5-3.3L-1088.9,941.3 M-1088.5,940.3l-1.3,1l0.2,4.4l0.7,0.9l9.5,3.3
            l1.1-0.3l3-3.3l-0.4-1.6L-1088.5,940.3L-1088.5,940.3z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_bravo_6_" fill="var(--bravo)" points="-1117.8,959.4 -1121.1,956.4 -1121,955.6 -1090.3,940.9 -1089.6,941.3 
            -1089.3,945.7 -1089.6,946.2 -1117.3,959.5"></polygon>
            <path fill="#FFFFFF" d="M-1090.1,941.3l0.2,4.4l-27.6,13.3l-3.3-3L-1090.1,941.3 M-1090.5,940.4l-30.7,14.7l-0.2,1.6l3.3,3l1.1,0.2
            l27.7-13.2l0.6-1l-0.2-4.4L-1090.5,940.4L-1090.5,940.4z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_bravo_5_" fill="var(--bravo)" points="-1107.3,998.4 -1107.6,998.1 -1121,970.1 -1121,969.8 -1117.6,960 
            -1117.3,959.7 -1089.4,946.3 -1089,946.3 -1079.3,949.7 -1079,950 -1065.6,978 -1065.6,978.4 -1069,988.1 -1069.2,988.4 
            -1097.2,1001.7 -1097.6,1001.8"></polygon>
            <path fill="#FFFFFF" d="M-1089.2,946.8l9.7,3.4l13.4,28l-3.4,9.7l-27.9,13.4l-9.8-3.4l-13.4-28l3.5-9.8L-1089.2,946.8 M-1088.8,945.8
            h-0.8l-27.9,13.4l-0.5,0.6l-3.5,9.8v0.8l13.4,28l0.6,0.5l9.8,3.4h0.8l27.9-13.4l0.5-0.6l3.4-9.7v-0.8l-13.4-28l-0.6-0.5
            L-1088.8,945.8L-1088.8,945.8z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_bravo_4_" fill="var(--bravo)" points="-1097,1006.7 -1097.2,1002.3 -1097,1001.8 -1069.3,988.6 -1068.7,988.7 
            -1065.5,991.6 -1065.6,992.5 -1096.3,1007.2"></polygon>
            <path fill="#FFFFFF" d="M-1069.1,989l3.3,3l-30.7,14.7l-0.2-4.4L-1069.1,989 M-1069.5,988.1l-27.7,13.2l-0.6,1l0.2,4.4l1.4,0.8
            l30.7-14.7l0.2-1.6l-3.3-3L-1069.5,988.1L-1069.5,988.1z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_bravo_3_" fill="var(--bravo)" points="-1068.6,988.5 -1068.8,988 -1065.4,978.6 -1065,978.2 -1060.6,978 
            -1060.1,978.6 -1064.5,991.3 -1065.3,991.5"></polygon>
            <path fill="#FFFFFF" d="M-1060.5,978.5l-4.5,12.6l-3.3-3l3.3-9.4L-1060.5,978.5 M-1060.6,977.5l-4.4,0.2l-0.9,0.7l-3.3,9.4l0.3,1.1
            l3.3,3l1.6-0.4l4.5-12.6L-1060.6,977.5L-1060.6,977.5z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_bravo_2_" fill="var(--bravo)" points="-1110.6,1002.8 -1110.8,1002 -1107.8,998.7 -1107.3,998.6 -1097.8,1001.9 
            -1097.4,1002.3 -1097.2,1006.7 -1097.8,1007.2"></polygon>
            <path fill="FFFFFF" d="M-1107.4,999.1l9.5,3.3l0.2,4.4l-12.7-4.4L-1107.4,999.1 M-1107.1,998.1l-1.1,0.3l-3,3.3l0.4,1.6l12.7,4.4
            l1.3-1l-0.2-4.4l-0.7-0.9L-1107.1,998.1L-1107.1,998.1z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_bravo_1_" fill="var(--bravo)" points="-1126.5,969.5 -1122,956.8 -1121.2,956.6 -1117.9,959.5 -1117.8,960.1 
            -1121.2,969.6 -1121.6,969.9 -1126,970.1"></polygon>
            <path fill="#FFFFFF" d="M-1121.5,956.9l3.3,3l-3.4,9.5l-4.4,0.2L-1121.5,956.9 M-1120.9,956.2l-1.6,0.4l-4.5,12.7l1,1.3l4.4-0.2
            l0.9-0.7l3.4-9.5l-0.3-1.1L-1120.9,956.2L-1120.9,956.2z"></path>
          </g>
        </g>
        <g id="jewel02" ref={refJewel02} onMouseEnter={() => {handleJewel(refJewel02.current)}} onMouseLeave={() => {handleJewel(refJewel02.current, 'down')}}>
          <path id="gradient_x5F_gem_1_" fill="url(#contentGradient)" d="M-1169.5,1009.5l-0.4-0.8l3.7-5.9l0.4-0.2l19.5,0.6l0.4,0.3l3.1,6.1l-0.5,0.7
          L-1169.5,1009.5z M-1142.4,1009.4l-3.1-6.1v-0.5l11-16l0.4-0.2l7,0.5l0.4,0.8l-14.9,21.7L-1142.4,1009.4z M-1171,1008.6l-15.3-30.1
          l0.5-0.7l7,0.4l0.4,0.3l12.1,23.8v0.5l-3.7,5.9L-1171,1008.6z M-1165.7,1002.3l-0.4-0.3l-12-23.7v-0.5l11.1-16l0.4-0.2l19.5,0.5
          l0.4,0.3l12,23.7v0.5l-11,16.1l-0.4,0.2L-1165.7,1002.3z M-1134,986.2l-0.4-0.3l-12.1-23.8v-0.5l3.8-5.9h0.9l15.3,30.1l-0.5,0.7
          L-1134,986.2z M-1185.8,977.4l-0.4-0.8l14.9-21.7l0.9,0.1l3.1,6.1v0.5l-11.1,16l-0.4,0.2L-1185.8,977.4z M-1166.6,961.3l-0.4-0.3
          l-3.1-6.1l0.5-0.7l26.3,0.8l0.4,0.8l-3.8,5.9l-0.4,0.2L-1166.6,961.3z"></path>
          <path fill="#FFFFFF" d="M-1169.6,954.7l26.3,0.8l-3.8,5.9l-19.5-0.5L-1169.6,954.7 M-1170.8,955.2l3.1,6.1l-11.1,16l-7-0.4
          L-1170.8,955.2 M-1142.2,956.1l15.3,30.1l-7-0.5L-1146,962L-1142.2,956.1 M-1166.6,962.1l19.5,0.5l12,23.7l-11.1,16.1l-19.5-0.6
          l-12-23.7L-1166.6,962.1 M-1185.8,978.2l7,0.4l12.1,23.8l-3.7,5.9L-1185.8,978.2 M-1134,987l7,0.5l-15,21.7l-3.1-6.1L-1134,987
          M-1165.7,1003.1l19.5,0.6l3.1,6.1l-26.3-0.7L-1165.7,1003.1 M-1169.6,953.7l-0.7,1.1l-1.3-0.1l-14.9,21.7l0.6,1.2l-0.7,1.1
          l15.3,30.1l1.3,0.1l0.6,1.2l26.3,0.7l0.7-1.1l1.3,0.1l14.9-21.7l-0.6-1.2l0.7-1.1l-15.3-30.1l-1.3-0.1l-0.6-1.2L-1169.6,953.7
          L-1169.6,953.7z"></path>
        </g>
        <g id="jewel03" ref={refJewel03} onMouseEnter={() => {handleJewel(refJewel03.current)}} onMouseLeave={() => {handleJewel(refJewel03.current, 'down')}}>
          <path id="colour_x5F_alpha" fill="var(--alpha)" d="M-1043.5,1009.3l-0.2-0.8l9.9-11.4l0.9,0.4l-0.7,15.1l-0.7,0.4L-1043.5,1009.3z
          M-1033.5,1012.2l0.8-16l0.2-0.4l20.3-15.9l0.4-0.1l15.7,3l0.2,0.9l-36.8,28.9L-1033.5,1012.2z M-1044.3,1007.9l-6.6-46.3l0.8-0.5
          l13.4,8.6l0.2,0.3l3.7,25.6l-0.1,0.4l-10.5,12.1L-1044.3,1007.9z M-1032.9,995.1l-3.5-24.3l0.7-0.5l22.7,9.1l0.1,0.9l-19.3,15.1
          L-1032.9,995.1z M-1010.6,980l-0.1-0.9l13.5-6.9l0.7,0.4l1.4,9.8l-0.6,0.6L-1010.6,980z M-1036.3,970l-0.3-0.3l-5.2-15.1l0.7-0.6
          l43.4,17.4v0.9l-14.2,7.3h-0.4L-1036.3,970z M-1050.4,960.9v-0.8l7.8-6.1l0.8,0.2l4.9,14.3l-0.7,0.6L-1050.4,960.9z"></path>
          <path fill="#FFFFFF" d="M-1042.4,954.4l4.9,14.3l-12.7-8.2L-1042.4,954.4 M-1041.3,954.4l43.4,17.4l-14.2,7.3l-24-9.6L-1041.3,954.4
          M-1050.4,961.6l13.4,8.6l3.7,25.6l-10.5,12.1L-1050.4,961.6 M-1035.8,970.7l22.7,9.1l-19.3,15.2L-1035.8,970.7 M-997.1,972.6
          l1.4,9.8l-14.9-2.9L-997.1,972.6 M-1011.9,980.3l15.7,3l-36.8,28.9l0.8-16L-1011.9,980.3 M-1033.3,997.4l-0.7,15.1l-9.2-3.7
          L-1033.3,997.4 M-1040.9,953.5l-0.6,0.5l-1.5-0.4l-7.8,6.1l0.1,1.6l-0.7,0.4l6.6,46.3l0.8,0.2l0.4,1.5l9.2,3.7l1.3-0.9l0.7,0.4
          l36.8-28.9l-0.2-0.8l1.2-1.1l-1.4-9.8l-1.4-0.7v-0.8L-1040.9,953.5L-1040.9,953.5z"></path>
        </g>
        <g id="jewel04" ref={refJewel04} onMouseEnter={() => {handleJewel(refJewel04.current)}} onMouseLeave={() => {handleJewel(refJewel04.current, 'down')}}>
          <path id="colour_x5F_bravo" fill="var(--bravo)" d="M-947.1,1024.8l3.3-15.9l0.1-0.2l14.6-15.1l0.5-0.1l10.1,2.1l0.3,0.8l-28,28.9
          L-947.1,1024.8z M-962.3,987.3l0.6-0.7l10.1,2.1l0.4,0.3l7.4,19.6v0.3l-3.3,15.9l-1,0.1L-962.3,987.3z M-944.1,1008.1l-7.2-19
          l0.1-0.5l14.1-14.6l0.8,0.2l7.2,19l-0.1,0.5l-14.1,14.6L-944.1,1008.1z M-928.6,993.5l-0.4-0.3l-7.4-19.6v-0.3l3.3-15.9l1-0.1
          l14.2,37.6l-0.6,0.7L-928.6,993.5z M-961.8,986.7l-0.3-0.8l28-28.9l0.8,0.4l-3.3,15.9l-0.1,0.2l-14.6,15.1l-0.5,0.1L-961.8,986.7z"></path>
          <path fill="#FFFFFF" d="M-933.7,957.3l-3.3,15.9l-7.3,7.6l-7.3,7.5l-10.1-2.1l14.3-14.7l7.5-7.7L-933.7,957.3 M-932.7,957.5l3.2,8.4
          l11.1,29.2l-10.1-2.1l-3.7-9.8l-3.7-9.9L-932.7,957.5 M-936.7,974.3l7.2,19l-14.1,14.6l-3.5-9.3l-3.7-9.7L-936.7,974.3
          M-961.9,987.2l10.1,2.1l3.7,9.8l3.7,9.9l-3.3,15.9L-961.9,987.2 M-928.7,994l10.1,2.1l-14.3,14.7l-13.7,14.1l3.3-15.9l7.4-7.6
          L-928.7,994 M-934.4,956.6l-6.2,6.4l-7.5,7.7l-14.3,14.7l0.4,1.1l-0.8,0.9l14.2,37.6l1.4-0.1l1.2,0.7l13.7-14.1l14.3-14.7l-0.4-1.1
          l0.8-0.9l-11.1-29.2l-3.2-8.4l-1.4,0.1L-934.4,956.6L-934.4,956.6z"></path>
        </g>
        <g id="jewel05" ref={refJewel05} onMouseEnter={() => {handleJewel(refJewel05.current)}} onMouseLeave={() => {handleJewel(refJewel05.current, 'down')}}>
          <g>
            <polygon id="colour_x5F_alpha_16_" fill="var(--alpha)" points="-1107.2,994.4 -1105.6,988 -1105.4,987.8 -1103.8,986.1 -1102.9,986.6 
            -1106.3,994.7"></polygon>
            <path fill="#FFFFFF" d="M-1103.4,986.4l-3.3,8l1.7-6.4L-1103.4,986.4 M-1104.1,985.7l-1.7,1.7l-0.3,0.5l-1.7,6.4l1.9,0.6l3.3-8
            L-1104.1,985.7L-1104.1,985.7z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_15_" fill="var(--alpha)" points="-1105.8,987.3 -1113.8,972.7 -1113.7,972.1 -1109.5,967.9 -1108.7,968 
            -1102.1,984 -1102.2,984.6 -1105,987.4"></polygon>
            <path fill="#FFFFFF" d="M-1109.2,968.2l6.6,16l-2.8,2.8l-8-14.6L-1109.2,968.2 M-1109.9,967.5l-4.2,4.2l-0.2,1.2l8,14.6l1.6,0.2
            l2.8-2.8l0.2-1.1l-6.6-16L-1109.9,967.5L-1109.9,967.5z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_14_" fill="var(--alpha)" points="-1124.2,1006.6 -1122.6,1004.9 -1122.4,1004.8 -1116,1003.1 
            -1115.7,1004.1 -1123.7,1007.4"></polygon>
            <path fill="#FFFFFF" d="M-1115.9,1003.6l-8,3.3l1.7-1.7L-1115.9,1003.6 M-1116.1,1002.6l-6.3,1.7l-0.5,0.3l-1.7,1.7l1.1,1.6l8-3.3
            L-1116.1,1002.6L-1116.1,1002.6z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_13_" fill="var(--alpha)" points="-1142.3,1001.6 -1142.4,1000.8 -1138.3,996.6 -1137.7,996.5 
            -1123.1,1004.5 -1122.9,1005.3 -1125.8,1008.1 -1126.3,1008.2"></polygon>
            <path fill="#FFFFFF" d="M-1137.9,996.9l14.6,8l-2.8,2.8l-16-6.6L-1137.9,996.9 M-1137.4,996.1l-1.2,0.2l-4.2,4.2l0.3,1.6l16,6.6
            l1.1-0.2l2.8-2.8l-0.2-1.6L-1137.4,996.1L-1137.4,996.1z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_12_" fill="var(--alpha)" points="-1149.1,982 -1145.7,974 -1144.8,974.3 -1146.5,980.7 -1146.6,980.9 
            -1148.2,982.6"></polygon>
            <path fill="#FFFFFF" d="M-1145.3,974.2l-1.7,6.4l-1.7,1.7L-1145.3,974.2 M-1146.2,973.8l-3.3,8l1.6,1.1l1.7-1.7l0.3-0.5l1.7-6.4
            L-1146.2,973.8L-1146.2,973.8z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_11_" fill="var(--alpha)" points="-1143.3,1000.6 -1149.9,984.6 -1149.8,984.1 -1147,981.3 -1146.2,981.4 
            -1138.2,996 -1138.3,996.6 -1142.4,1000.8"></polygon>
            <path fill="#FFFFFF" d="M-1146.6,981.6l8,14.6l-4.2,4.2l-6.6-16L-1146.6,981.6 M-1147.3,980.9l-2.8,2.8l-0.2,1.1l6.6,16l1.6,0.3
            l4.2-4.2l0.2-1.2l-8-14.6L-1147.3,980.9L-1147.3,980.9z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_10_" fill="var(--alpha)" points="-1136.4,964.6 -1128.3,961.3 -1127.8,962.1 -1129.4,963.8 -1129.7,963.9 
            -1136.1,965.6"></polygon>
            <path fill="#FFFFFF" d="M-1128.1,961.7l-1.7,1.7l-6.4,1.7L-1128.1,961.7 M-1128.5,960.8l-8.1,3.3l0.6,1.9l6.4-1.7l0.5-0.3l1.7-1.7
            L-1128.5,960.8L-1128.5,960.8z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_9_" fill="var(--alpha)" points="-1121.2,1003.5 -1114.3,996.7 -1113.6,996.7 -1109.6,1000.7 
            -1109.9,1001.5 -1120.7,1004.4"></polygon>
            <path fill="#FFFFFF" d="M-1114,997l4,4l-10.8,2.8L-1114,997 M-1113.3,996.3h-1.4l-6.8,6.8l1,1.7l10.8-2.8l0.5-1.7L-1113.3,996.3
            L-1113.3,996.3z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_8_" fill="var(--alpha)" points="-1128.9,964.2 -1129.1,963.4 -1126.2,960.5 -1125.7,960.4 -1109.7,967.1 
            -1109.5,967.9 -1113.7,972.1 -1114.3,972.2"></polygon>
            <path fill="#FFFFFF" d="M-1125.9,960.9l16,6.6l-4.2,4.2l-14.6-8L-1125.9,960.9 M-1125.5,960l-1.1,0.2l-2.8,2.8l0.2,1.6l14.6,8
            l1.2-0.2l4.2-4.2l-0.3-1.6L-1125.5,960L-1125.5,960z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_7_" fill="var(--alpha)" points="-1126.3,984 -1137.7,972.7 -1137.7,972 -1129.8,964.1 -1129.2,964 
            -1114.6,972 -1114.4,972.8 -1125.6,984"></polygon>
            <path fill="#FFFFFF" d="M-1129.4,964.5l14.6,8l-11.2,11.1l-11.3-11.3L-1129.4,964.5 M-1129,963.6l-1.2,0.2l-7.9,7.9v1.4l11.3,11.3
            h1.4l11.2-11.2l-0.2-1.6L-1129,963.6L-1129,963.6z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_6_" fill="var(--alpha)" points="-1113.6,996.7 -1113.6,996 -1106.8,989.1 -1106,989.6 -1108.8,1000.5 
            -1109.6,1000.7"></polygon>
            <path fill="#FFFFFF" d="M-1106.5,989.5l-2.8,10.8l-4-4L-1106.5,989.5 M-1107.2,988.8l-6.8,6.8v1.4l4,4l1.7-0.5l2.8-10.8
            L-1107.2,988.8L-1107.2,988.8z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_5_" fill="var(--alpha)" points="-1114.3,996 -1125.6,984.7 -1125.6,984 -1114.5,972.8 -1113.7,972.9 
            -1105.7,987.5 -1105.8,988.1 -1113.6,996"></polygon>
            <path fill="#FFFFFF" d="M-1114.1,973.1l8,14.6l-7.9,7.9l-11.3-11.3L-1114.1,973.1 M-1114.8,972.4l-11.2,11.2v1.4l11.3,11.3h1.4
            l7.9-7.9l0.2-1.2l-8-14.6L-1114.8,972.4L-1114.8,972.4z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_4_" fill="var(--alpha)" points="-1146,979.1 -1143.2,968.2 -1142.4,968 -1138.4,972 -1138.4,972.7 
            -1145.2,979.5"></polygon>
            <path fill="#FFFFFF" d="M-1142.7,968.3l4,4l-6.8,6.8L-1142.7,968.3 M-1142,967.6l-1.7,0.5l-2.8,10.9l1.7,1l6.8-6.8v-1.4L-1142,967.6
            L-1142,967.6z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_3_" fill="var(--alpha)" points="-1138.4,972 -1142.4,968 -1142.1,967.1 -1131.3,964.3 -1130.8,965.1 
            -1137.7,972"></polygon>
            <path fill="#FFFFFF" d="M-1131.2,964.8l-6.8,6.8l-4-4L-1131.2,964.8 M-1131.4,963.8l-10.9,2.8l-0.5,1.7l4,4h1.4l6.8-6.8
            L-1131.4,963.8L-1131.4,963.8z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_2_" fill="var(--alpha)" points="-1138.3,995.8 -1146.3,981.1 -1146.2,980.6 -1138.4,972.7 -1137.7,972.7 
            -1126.3,984 -1126.3,984.7 -1137.5,995.9"></polygon>
            <path fill="#FFFFFF" d="M-1138,973l11.3,11.3l-11.2,11.2l-8-14.6L-1138,973 M-1138.7,972.3l-7.9,7.9l-0.2,1.2l8,14.6l1.6,0.2
            l11.2-11.2v-1.4l-11.3-11.3H-1138.7L-1138.7,972.3z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_1_" fill="var(--alpha)" points="-1137.4,996.7 -1137.5,995.9 -1126.3,984.7 -1125.6,984.7 -1114.3,996 
            -1114.3,996.7 -1122.2,1004.6 -1122.8,1004.7"></polygon>
            <path fill="#FFFFFF" d="M-1126,985l11.3,11.3l-7.9,7.9l-14.6-8L-1126,985 M-1125.3,984.3h-1.4l-11.2,11.2l0.2,1.6l14.6,8l1.2-0.2
            l7.9-7.9v-1.4L-1125.3,984.3L-1125.3,984.3z"></path>
          </g>
        </g>
        <g id="jewel06" ref={refJewel06} onMouseEnter={() => {handleJewel(refJewel06.current)}} onMouseLeave={() => {handleJewel(refJewel06.current, 'down')}}>
          <path id="gradient_x5F_gem_x5F_reverse" fill="url(#contentGradientFlipped)" d="M-975.2,1027.3c-1,0-2.1,0-3.1-0.1l-0.4-0.6l4.5-16.5l0.2-0.3l13.4-7.7h0.4
          l16.5,4.5l0.3,0.7c-3.1,6.5-8,11.9-14.3,15.5C-963.1,1025.7-969.1,1027.3-975.2,1027.3z M-979.9,1027c-9.5-1.3-18.2-6.4-23.9-14.1
          l0.2-0.7l14-8h0.4l14,5.5l0.3,0.6l-4.4,16.3L-979.9,1027z M-1004.6,1011.7c-0.5-0.8-0.9-1.5-1.3-2.1c-0.5-0.9-5.4-9.3-7.7-21.3
          c-0.3-1.8-0.6-3.7-0.8-5.6l0.6-0.5l18.4,5l0.3,0.3l5.2,15.9l-0.2,0.6l-13.9,7.9L-1004.6,1011.7z M-989,1003.7l-0.3-0.3l-5.3-16.3
          v-0.3l5-19.1l0.6-0.4l19,5.3l0.2,0.2l11.4,12.9l0.1,0.4l-2.3,15.2l-0.2,0.4l-13.3,7.6h-0.4L-989,1003.7z M-959.8,1001.7l-0.4-0.6
          l2.3-14.9l0.2-0.4l14-8l0.7,0.2c3.8,8.8,3.8,18.9,0.1,27.7l-0.6,0.3L-959.8,1001.7z M-1014.2,981.5l-0.4-0.4
          c-1.1-13.9,2.1-27,9.6-38.8h0.9l14.1,24.5v0.4l-5,19l-0.6,0.4L-1014.2,981.5z M-958.2,985.2l-11.1-12.5l-0.1-0.5l5-18.4l0.8-0.3
          c1.5,1.1,3,2.3,4.4,3.5c9.8,8.5,14.5,17.4,14.6,17.5c0.4,0.6,0.7,1.3,1.1,2.1l-0.2,0.7l-13.9,7.9H-958.2z M-989.3,966.8l-0.3-0.2
          l-14.1-24.6l0.4-0.7c0.8,0,1.5,0,2.2,0c13.1,0,25.2,3.8,36.1,11.4l0.2,0.5l-5,18.6l-0.6,0.4L-989.3,966.8z"></path>
          <path fill="#FFFFFF" d="M-1001,941.7c15.4,0,27.3,5.4,35.8,11.3l-5,18.6l-18.9-5.3l-14.1-24.5C-1002.5,941.7-1001.8,941.7-1001,941.7
          M-1004.6,942.5l14.1,24.5l-5,19l-18.6-5C-1014.9,970.2-1013.4,956.4-1004.6,942.5 M-963.9,954c1.6,1.2,3,2.3,4.4,3.5
          c9.7,8.4,14.4,17.2,14.5,17.3l0,0c0.4,0.7,0.8,1.4,1.1,2.1l-13.9,7.9l-11.1-12.5L-963.9,954 M-989.2,967.9l19,5.3l11.4,12.9
          l-2.3,15.2l-13.3,7.6l-14.3-5.7l-5.3-16.3L-989.2,967.9 M-943.4,978.3c3.9,8.9,3.7,18.8,0.1,27.3l-16.3-4.4l2.3-14.9L-943.4,978.3
          M-1013.9,982.6l18.4,5l5.2,15.9l-13.9,7.9c-0.4-0.7-0.9-1.3-1.3-2.1c-0.1-0.1-5.2-8.6-7.6-21.2l0,0
          C-1013.4,986.4-1013.7,984.6-1013.9,982.6 M-960.4,1002.5l16.5,4.5c-2.9,6.2-7.7,11.6-14.1,15.2c-5.4,3.1-11.3,4.6-17.2,4.6
          c-1,0-2.1,0-3.1-0.1l0,0l4.5-16.5L-960.4,1002.5 M-989.4,1004.6l14,5.5l-4.4,16.3c-9.2-1.2-17.8-6-23.5-13.9l0,0L-989.4,1004.6
          M-943.3,1005.6L-943.3,1005.6 M-1001,940.7L-1001,940.7c-0.8,0-1.5,0-2.3,0l-0.7,1.3h-1.4c-7.5,11.9-10.8,25-9.6,39.1l0.7,0.9
          l0.2,0.1l-0.8,0.7c0.2,1.9,0.5,3.8,0.8,5.7c2.3,12.2,7.2,20.6,7.7,21.5c0.4,0.6,0.8,1.3,1.3,2.1l1.1,0.3l-0.2,1
          c5.7,7.8,14.5,13,24.2,14.3l1.1-0.7l0.1-0.2l0.2,0.1l0.2,1.1c1,0.1,2.1,0.1,3.2,0.1c6.2,0,12.3-1.6,17.7-4.7
          c6.3-3.6,11.4-9.1,14.5-15.7l-0.4-0.9l1.1-0.5c3.8-9,3.8-19.2-0.1-28.1l-1-0.3l0.3-1.1c-0.4-0.9-0.8-1.6-1.1-2.2
          c0-0.1-4.8-9-14.7-17.6c-1.4-1.2-2.9-2.4-4.4-3.5l-1,0.3l0.1-0.2l-0.4-1.1C-975.6,944.6-987.8,940.7-1001,940.7L-1001,940.7z
          M-989.5,967.3L-989.5,967.3L-989.5,967.3L-989.5,967.3L-989.5,967.3z M-970,972.2L-970,972.2L-970,972.2L-970,972.2L-970,972.2
          L-970,972.2z M-958,985.7L-958,985.7L-958,985.7L-958,985.7L-958,985.7z M-995.2,986.7L-995.2,986.7L-995.2,986.7L-995.2,986.7
          L-995.2,986.7L-995.2,986.7z M-960.3,1001.6L-960.3,1001.6L-960.3,1001.6L-960.3,1001.6L-960.3,1001.6z M-989.6,1003.8
          L-989.6,1003.8L-989.6,1003.8L-989.6,1003.8L-989.6,1003.8z M-974.7,1009.8L-974.7,1009.8L-974.7,1009.8L-974.7,1009.8
          L-974.7,1009.8z"></path>
        </g>
        <g id="jewel07" ref={refJewel07} onMouseEnter={() => {handleJewel(refJewel07.current)}} onMouseLeave={() => {handleJewel(refJewel07.current, 'down')}}>
          <path id="gradient_x5F_gem" fill="url(#contentGradient)" d="M-1072.7,1033.7l-2-17.9l0.6-0.5l12.7,3.4l0.3,0.8l-10.7,14.5L-1072.7,1033.7z
          M-1070.3,1032.1l9.6-13l0.3-0.2l9.3-1.1l0.4,0.9l-19,14.1L-1070.3,1032.1z M-1083.5,1009.9l0.8-0.6l7.5,5.6l0.2,0.3l1.8,16.1
          l-1,0.3L-1083.5,1009.9z M-1060.3,1018.1l11.8-43.9l0.4-0.4l10.5-1.2l0.5,0.6l-11.8,43.9l-0.4,0.4l-10.5,1.2L-1060.3,1018.1z
          M-1074.3,1015.1l-0.4-0.6l11.8-44l0.6-0.4l13.2,3.6l0.4,0.6l-11.8,44l-0.6,0.4L-1074.3,1015.1z M-1084,1008.1l-0.2-0.5l11.8-43.9
          l0.8-0.3l8.5,6.3l0.2,0.5l-11.8,43.9l-0.8,0.3L-1084,1008.1z M-1048.4,973.1v-25.2l1-0.2l10.3,23.9l-0.4,0.7l-10.3,1.2
          L-1048.4,973.1z M-1062.1,970l-0.3-0.7l12.9-22.2l0.9,0.3v25.7l-0.6,0.5L-1062.1,970z M-1071.6,963.3v-0.8l20.9-15.6l0.7,0.7
          l-12.6,21.8l-0.7,0.1L-1071.6,963.3z"></path>
          <path fill="#FFFFFF" d="M-1050.4,947.3l-12.6,21.8l-8.3-6.2L-1050.4,947.3 M-1049,947.3V973l-12.9-3.5L-1049,947.3 M-1047.9,948
          l10.3,23.9l-10.3,1.2V948 M-1071.9,963.8l8.5,6.3l-11.9,43.9l-8.5-6.3L-1071.9,963.8 M-1062.4,970.6l13.2,3.6l-11.8,44l-13.2-3.6
          L-1062.4,970.6 M-1037.5,973.1l-11.8,43.9l-10.5,1.2l11.8-43.9L-1037.5,973.1 M-1083,1009.7l7.5,5.6l1.8,16.1L-1083,1009.7
          M-1074.3,1015.8l12.7,3.4l-10.7,14.5L-1074.3,1015.8 M-1050.9,1018.3l-19,14.1l9.6-13L-1050.9,1018.3 M-1051,946.5l-20.9,15.6v1.2
          l-1,0.3l-11.8,43.9l0.4,1.1l1.2,0.9l-0.8,0.6l9.3,21.7l1.1-0.3l0.3,2.3l1.8,0.5l1.4-1.8l0.8,0.8l19-14.1l-0.4-1l1.5-0.2l0.8-0.7
          l11.8-43.9l-0.7-0.8l0.6-1l-10.4-24l-1.1,0.2v-0.5l-1.9-0.5l-0.2,0.4L-1051,946.5L-1051,946.5z M-1062.7,969.7h0.2l0.1,0.2
          l-0.2,0.1L-1062.7,969.7L-1062.7,969.7z M-1048.7,973.5l0.2-0.1l0.1,0.1l-0.2,0.2L-1048.7,973.5L-1048.7,973.5z"></path>
        </g>
        <g id="jewel08" ref={refJewel08} onMouseEnter={() => {handleJewel(refJewel08.current)}} onMouseLeave={() => {handleJewel(refJewel08.current, 'down')}}>
          <path id="colour_x5F_bravo_10_" fill="var(--bravo)" d="M-1222,992.7v-0.9l11.6-5.8h0.5l22.8,13.6l0.2,0.2l9.2,18.3l-0.7,0.7L-1222,992.7z
          M-1186.6,999.7v-0.3l2.6-26.4l0.3-0.4l11.6-5.8l0.7,0.5l-5.1,50.5l-0.9,0.2L-1186.6,999.7z M-1209.7,985.8l-0.2-0.5l2.6-25.5
          l0.8-0.4l22,13.2l0.2,0.5l-2.6,25.5l-0.8,0.4L-1209.7,985.8z M-1222.8,991.1l5.1-50.5l0.9-0.2l9.2,18.3v0.3l-1.3,13.3l-1.3,13.1
          l-0.3,0.4l-11.6,5.8L-1222.8,991.1z M-1184.4,972.4l-22.8-13.6l-0.2-0.2l-9.2-18.3l0.7-0.7l43.6,26.1v0.9l-11.6,5.8H-1184.4z"></path>
          <path fill="#FFFFFF" d="M-1216.1,940l9.7,5.8l33.9,20.3l-11.6,5.8l-11.3-6.8l-11.5-6.9L-1216.1,940 M-1217.2,940.6l9.2,18.3l-1.3,13.3
          l-1.3,13.1l-11.6,5.8l2.6-25.8l1.4-13.5L-1217.2,940.6 M-1206.8,959.8l22,13.2l-2.6,25.5l-10.8-6.4l-11.3-6.7L-1206.8,959.8
          M-1171.9,967.2l-2.6,25.8l-2.5,24.8l-9.2-18.3l1.3-13.3l1.3-13.1L-1171.9,967.2 M-1210.1,986.4l11.3,6.8l11.5,6.9l9.2,18.3
          l-43.6-26.1L-1210.1,986.4 M-1215.6,939.1l-1.1,1.1l-1.5,0.3l-1.1,11.3l-1.4,13.5l-2.6,25.7l1.1,0.8l-0.1,1.3l43.6,26.1l1.1-1.1
          l1.5-0.3l2.5-24.8l2.6-25.8l-1.1-0.8l0.1-1.3l-33.8-20.1L-1215.6,939.1L-1215.6,939.1z M-1207.1,959.3L-1207.1,959.3L-1207.1,959.3
          L-1207.1,959.3L-1207.1,959.3z"></path>
        </g>
        <g id="jewel09" ref={refJewel09} onMouseEnter={() => {handleJewel(refJewel09.current)}} onMouseLeave={() => {handleJewel(refJewel09.current, 'down')}}>
          <g>
            <polygon id="colour_x5F_alpha_25_" fill="var(--alpha)" points="-1269.7,1013.1 -1282.9,985.6 -1282.5,984.9 -1278.5,984.6 -1278.1,984.9 
            -1266.2,1009.7 -1266.3,1010.2 -1268.9,1013.2"></polygon>
            <path fill="#FFFFFF" d="M-1278.5,985.1l11.9,24.8l-2.6,2.9l-13.2-27.5L-1278.5,985.1 M-1278.6,984.1l-3.9,0.2l-0.8,1.4l13.2,27.5
            l1.6,0.2l2.6-2.9l0.2-1.1l-11.9-24.8L-1278.6,984.1L-1278.6,984.1z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_24_" fill="var(--alpha)" points="-1228.4,991.6 -1240.2,966.8 -1240.2,966.3 -1237.5,963.3 -1236.7,963.5 
            -1223.5,990.9 -1224,991.7 -1227.9,991.9"></polygon>
            <path fill="#FFFFFF" d="M-1237.2,963.7l13.2,27.5l-3.9,0.2l-11.9-24.8L-1237.2,963.7 M-1237.9,963l-2.6,2.9l-0.2,1.1l11.9,24.8l1,0.6
            l3.9-0.2l0.8-1.4l-13.2-27.5L-1237.9,963L-1237.9,963z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_23_" fill="var(--alpha)" points="-1249.2,963.4 -1249.5,962.9 -1249.8,959 -1249.1,958.5 -1237.8,962.5 
            -1237.6,963.3 -1240.2,966.2 -1240.8,966.4"></polygon>
            <path fill="#FFFFFF" d="M-1249.3,959l11.3,4l-2.6,2.9l-8.5-3L-1249.3,959 M-1248.9,958l-1.3,1l0.2,4l0.7,0.9l8.5,3l1.1-0.3l2.6-2.9
            l-0.4-1.6L-1248.9,958L-1248.9,958z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_22_" fill="var(--alpha)" points="-1275.2,975.2 -1278.1,972.5 -1278,971.7 -1250.5,958.6 -1249.8,959 
            -1249.6,962.9 -1249.9,963.4 -1274.6,975.3"></polygon>
            <path fill="#FFFFFF" d="M-1250.3,959l0.2,3.9l-24.7,11.8l-2.9-2.6L-1250.3,959 M-1250.7,958.1l-27.5,13.1l-0.2,1.6l2.9,2.6l1.1,0.2
            l24.7-11.8l0.6-1l-0.2-3.9L-1250.7,958.1L-1250.7,958.1z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_21_" fill="var(--alpha)" points="-1265.8,1010.1 -1266.1,1009.8 -1278.1,984.8 -1278.1,984.4 -1275,975.7 
            -1274.7,975.4 -1249.7,963.4 -1249.4,963.4 -1240.6,966.5 -1240.4,966.7 -1228.4,991.7 -1228.4,992.1 -1231.4,1000.8 
            -1231.7,1001.1 -1256.7,1013.1 -1257,1013.1"></polygon>
            <path fill="#FFFFFF" d="M-1249.5,963.9l8.7,3.1l12,25l-3.1,8.7l-25,12l-8.8-3l-12-25l3.1-8.7L-1249.5,963.9 M-1249.2,962.9h-0.8
            l-25,12l-0.5,0.6l-3.1,8.7v0.8l12,25l0.6,0.5l8.8,3h0.8l25-12l0.4-0.5l3.1-8.7v-0.8l-12-25l-0.6-0.5L-1249.2,962.9L-1249.2,962.9z
            "></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_20_" fill="var(--alpha)" points="-1256.6,1017.5 -1256.8,1013.5 -1256.5,1013.1 -1231.8,1001.2 
            -1231.2,1001.3 -1228.3,1003.9 -1228.4,1004.8 -1255.9,1017.9"></polygon>
            <path fill="#FFFFFF" d="M-1231.6,1001.7l2.9,2.6l-27.5,13.1l-0.2-3.9L-1231.6,1001.7 M-1232,1000.8l-24.7,11.8l-0.6,1l0.2,3.9
            l1.4,0.8l27.5-13.1l0.2-1.6l-2.9-2.6L-1232,1000.8L-1232,1000.8z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_19_" fill="var(--alpha)" points="-1231.2,1001.3 -1231.3,1000.7 -1228.3,992.3 -1227.9,991.9 -1224,991.7 
            -1223.5,992.4 -1227.4,1003.7 -1228.3,1003.9"></polygon>
            <path fill="#FFFFFF" d="M-1223.9,992.2l-4,11.3l-2.9-2.6l3-8.4L-1223.9,992.2 M-1224,991.2l-3.9,0.2l-0.9,0.7l-3,8.4l0.3,1.1l2.9,2.6
            l1.6-0.4l4-11.3L-1224,991.2L-1224,991.2z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_18_" fill="var(--alpha)" points="-1268.7,1014 -1268.9,1013.2 -1266.2,1010.3 -1265.7,1010.1 
            -1257.2,1013.1 -1256.9,1013.5 -1256.6,1017.5 -1257.3,1018"></polygon>
            <path fill="#FFFFFF" d="M-1265.9,1010.6l8.5,3l0.2,3.9l-11.4-4L-1265.9,1010.6 M-1265.5,1009.7l-1.1,0.3l-2.6,2.9l0.4,1.6l11.4,4
            l1.3-1l-0.2-3.9l-0.7-0.9L-1265.5,1009.7L-1265.5,1009.7z"></path>
          </g>
          <g>
            <polygon id="colour_x5F_alpha_17_" fill="var(--alpha)" points="-1283,984.1 -1278.9,972.8 -1278.1,972.6 -1275.2,975.2 -1275.1,975.8 
            -1278.1,984.3 -1278.5,984.6 -1282.5,984.8"></polygon>
            <path fill="#FFFFFF" d="M-1278.5,972.9l2.9,2.6l-3,8.5l-3.9,0.2L-1278.5,972.9 M-1277.8,972.2l-1.6,0.4l-4,11.4l1,1.3l3.9-0.2
            l0.9-0.7l3-8.5l-0.3-1.1L-1277.8,972.2L-1277.8,972.2z"></path>
          </g>
        </g>
        <g id="jewel10" ref={refJewel10} onMouseEnter={() => {handleJewel(refJewel10.current)}} onMouseLeave={() => {handleJewel(refJewel10.current, 'down')}}>
          <path id="gradient_x5F_gem_2_" fill="url(#contentGradient)" d="M-1227.1,1007.5l3.7-14.7l0.9-0.1l6.2,13.8l-0.4,0.7l-9.9,0.9L-1227.1,1007.5z
          M-1254.8,969l0.5-0.8l15.9,1.6l0.4,0.2l14.8,21.1l0.1,0.4l-3.9,15.6l-0.9,0.2L-1254.8,969z M-1216.4,1006.2l-6.6-14.6v-0.4
          l10.9-23.4l0.3-0.3l15.4-4.4l0.6,0.7l-19.7,42.4H-1216.4z M-1237.7,970.6l0.4-0.8l24.4-2.1l0.5,0.7l-10.3,22.2l-0.9,0.1
          L-1237.7,970.6z M-1238.3,969.7l-11.5-11.1l0.3-0.9l46.6-4.1l0.4,0.8l-9.4,13l-0.4,0.2l-25.7,2.3L-1238.3,969.7z M-1254.7,968.1
          l-0.4-0.7l4.2-9l0.8-0.1l10.9,10.5l-0.4,0.9L-1254.7,968.1z M-1211.1,966.3l8.9-12.3h0.8l5.7,8.1l-0.3,0.9l-14.6,4.2L-1211.1,966.3
          z"></path>
          <path fill="#FFFFFF" d="M-1202.9,954.1l-9.4,13l-25.7,2.2l-11.5-11.1L-1202.9,954.1 M-1201.8,954.4l5.7,8.1l-14.6,4.2L-1201.8,954.4
          M-1250.5,958.7l10.9,10.5l-15.1-1.5L-1250.5,958.7 M-1196.3,963.6l-19.7,42.4l-6.5-14.6l10.9-23.4L-1196.3,963.6 M-1212.8,968.1
          l-10.3,22.2l-14.1-20.1L-1212.8,968.1 M-1254.4,968.7l15.9,1.6l14.8,21.1l-3.8,15.6L-1254.4,968.7 M-1222.9,992.9l6.2,13.8
          l-9.9,0.9L-1222.9,992.9 M-1203,953.1l-46.6,4.1l-0.3,0.7l-1.6,0.3l-4.2,9l0.8,1.4l-0.4,0.7l26.8,38.3l0.8-0.1l1,1.2l9.9-0.9
          l0.8-1.4h0.8l19.7-42.4l-0.5-0.6l0.5-1.5l-5.7-8.1h-1.6L-1203,953.1L-1203,953.1z"></path>
        </g>
      </g>
      {/* end jewels */}
      <g>
        <path fill="#FFFFFF" d="M-899,1002.8h-400v140.5c0,10.8,8.7,19.5,19.5,19.5h361c10.8,0,19.5-8.7,19.5-19.5V1002.8z"></path>
        <polygon fill="#EEEEEE" points="-1098.7,1072 -1098.7,1002.8 -1068.6,1043.1 	"></polygon>
        <polygon fill="#E2E2E3" points="-1098.7,1002.8 -1068.6,1043.1 -934,1002.8 	"></polygon>
        <polygon fill="#EEEEEE" points="-898.5,1002.8 -1068.6,1043.1 -934,1002.8 	"></polygon>
        <polygon fill="#DCDDDD" points="-1076.3,1081.2 -1068.6,1043.1 -898.5,1002.8 -898.5,1079.7 	"></polygon>
        <polygon fill="#EEEEEE" points="-1076.3,1081.2 -898.5,1079.7 -898.5,1132.5 	"></polygon>
        <polygon fill="#DCDDDD" points="-1099,1122.5 -898.5,1132.5 -1076.3,1081.2 	"></polygon>
        <path fill="#EEEEEE" d="M-899,1143.3v-10.8l-200-9.9l169.6,40.3h10.9C-907.7,1162.8-899,1154-899,1143.3z"></path>
        <polygon fill="#DCDDDD" points="-1099,1122.5 -929.4,1162.8 -1050,1162.8 	"></polygon>
        <polygon fill="#B3B3B3" points="-1099,1162.8 -1050,1162.8 -1099,1122.5 	"></polygon>
        <polygon fill="#CCCCCC" points="-1098.5,1072 -1098.5,1002.8 -1128.6,1043.1 	"></polygon>
        <polygon fill="#E2E2E3" points="-1098.5,1002.8 -1128.6,1043.1 -1263.2,1002.8 	"></polygon>
        <polygon fill="#CCCCCC" points="-1298.7,1002.8 -1128.6,1043.1 -1263.2,1002.8 	"></polygon>
        <polygon fill="#DCDDDD" points="-1121,1081.2 -1128.6,1043.1 -1298.7,1002.8 -1298.7,1079.7 	"></polygon>
        <polygon fill="#CCCCCC" points="-1121,1081.2 -1298.7,1079.7 -1298.7,1132.5 	"></polygon>
        <polygon fill="#DCDDDD" points="-1098.2,1122.5 -1298.7,1132.5 -1121,1081.2 	"></polygon>
        <path fill="#B3B3B3" d="M-1298.7,1132.5l-0.1,13.1c1.1,9.7,9.4,17.2,19.4,17.2h11.6l169.6-40.3L-1298.7,1132.5z"></path>
        <polygon fill="#CCCCCC" points="-1098.2,1122.5 -1267.8,1162.8 -1147.2,1162.8 	"></polygon>
        <polygon fill="#999999" points="-1098.2,1162.8 -1147.2,1162.8 -1098.2,1122.5 	"></polygon>
        <polygon fill="#B3B3B3" points="-909,994.8 -1289,994.8 -1298.7,1002.6 -1298.7,1002.8 -899,1002.8 -899,1002.8 	"></polygon>
        <g>
          <path id="gradient_x5F_logo" fill="url(#contentGradient)" d="M-1122.3,1080.3l23.7-8.5v52.6L-1122.3,1080.3z M-1098,1071.9l23.9,8.4l-23.9,44.2
          V1071.9z M-1130.3,1041.1l31.3,30.1l-23.5,8.5L-1130.3,1041.1z M-1097.7,1071.2l31.5-30.7l-7.7,39.2L-1097.7,1071.2z"></path>
          <path fill="#FFFFFF" d="M-1067,1042l-7.3,37l-22.5-8L-1067,1042 M-1129.5,1042.6l29.6,28.5l-22.2,8L-1129.5,1042.6 M-1097.5,1072.6
          l22.7,8l-22.6,41.9L-1097.5,1072.6 M-1099.1,1072.6v49.9l-22.4-41.9L-1099.1,1072.6 M-1065.4,1039.1l-2.3,2.2l-29.8,29l-0.8,0.8
          l-0.9-0.8l-29.6-28.5l-2.3-2.2l0.6,3.1l7.4,36.5l0.2,0.8h-0.1l0.1,0.3l0,0l0,0l0.4,0.7l22.5,42l1.5,2.8v0.7l0.2-0.4l0.2,0.4v-0.8
          l1.5-2.7l22.6-41.9l0.4-0.8h0.1l0.2-1.1l7.3-37L-1065.4,1039.1L-1065.4,1039.1z"></path>
        </g>
      </g>
      <path fill="#999999" d="M-1094.7,1042.7c0,0-4.5,0.9-7.8,0l1.8-9.6c-1.1-0.7-1.9-1.9-1.9-3.3c0-2.2,1.9-3.9,4.2-3.9
      c2.3,0,4.2,1.8,4.2,3.9c0,1.4-0.7,2.6-1.9,3.3L-1094.7,1042.7z"></path>
    </svg>
  )
}

export default Content