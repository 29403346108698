import React, {useEffect, useRef } from 'react'
import { gsap } from 'gsap'

const Strategy = () => {
  const refOuterRing = useRef()
  const refInnerRing = useRef()
  const refDottedRing = useRef()
  const refCompass = useRef()
  useEffect(() => {
    gsap.set([refOuterRing.current, refInnerRing.current, refDottedRing.current, refCompass.current], {transformOrigin: '50% 50%'})
    gsap.to(refDottedRing.current, {
      rotate: '+=360',
      repeat: -1,
      duration: 12,
      ease: 'none'
    })
    gsap.from([refInnerRing.current, refOuterRing.current], {
      opacity: 0,
      scale: 1.5,
      stagger: 0.25,
      delay: 1,
      ease: 'elastic.out(1, 0.5)',
      duration: 2,
    })
  }, [])

  const getRange = (percent, min, max) => {
    return min + (max - min) * percent / 100;
  }
  const handleMove = (e) => {
    let xPos = (e.clientX - (refCompass.current.getBoundingClientRect().left)) / refCompass.current.getBoundingClientRect().width
    // console.log('xPos', xPos)
    gsap.to(refCompass.current, {
      rotate: getRange(xPos * 100, -30, 30),
      ease: 'power1.out'
    })
  }
  const handleOut = () => {
    gsap.to(refCompass.current, {
      rotate: 0
    })
  }
  return (
    <svg
      version="1.1"
      style={{overflow: 'visible'}}
      className="svgStrategy"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 400 400"
      onMouseMove={(e) => {handleMove(e)}}
      onMouseOut={handleOut}
    >
      <g id="back">
        <g id="compass" ref={refCompass}>
          <polygon fill="#F5F5F5" points="200,0 225,143.5 200,200"></polygon>
          <polygon fill="#E0E0E0" points="200,0 175,143.5 200,200"></polygon>
          <polygon fill="#F5F5F5" points="400,200 256.5,225.3 200,200"></polygon>
          <polygon fill="#E0E0E0" points="400,200 256.5,174.7 200,200"></polygon>
          <polygon fill="#F5F5F5" points="200,400 174.7,256.5 200,200"></polygon>
          <polygon fill="#E6E6E6" points="200,400 225.3,256.5 200,200"></polygon>
          <polygon fill="#F5F5F5" points="0,200 143.5,174.7 200,200"></polygon>
          <polygon fill="#E0E0E0" points="0,200 143.5,225.3 200,200"></polygon>
          <polygon fill="#F5F5F5" points="306.1,93.9 256.5,174.7 200,200"></polygon>
          <polygon fill="#E0E0E0" points="306.1,93.9 225.3,143.5 200,200"></polygon>
          <polygon fill="#F5F5F5" points="306.1,306.1 225.3,256.5 200,200"></polygon>
          <polygon fill="#E0E0E0" points="306.1,306.1 256.5,225.3 200,200"></polygon>
          <polygon fill="#F5F5F5" points="93.9,306.1 143.5,225.3 200,200"></polygon>
          <polygon fill="#E0E0E0" points="93.9,306.1 174.7,256.5 200,200"></polygon>
          <polygon fill="#F5F5F5" points="93.9,93.9 174.7,143.5 200,200"></polygon>
          <polygon fill="#E0E0E0" points="93.9,93.9 143.5,174.7 200,200"></polygon>
          <g id="front">
            <g>
              <defs>
                <linearGradient id="strategyGradient" gradientTransform="rotate(0)">
                  <stop offset="5%" stopColor="var(--bravo)"></stop><stop offset="95%" stopColor="var(--alpha)"></stop>
                </linearGradient>
              </defs>
              <path fill="#F5F5F5" d="M226.8,164.2l-6.7,34.4l-20.2,37.2L180,198.6l-6.9-33.9l26.8,25.8L226.8,164.2z M219,197.4l5.8-29.3l-23.6,23
                L219,197.4z M200.6,231.8l17.9-33.2l-18-6.4L200.6,231.8z M199.3,231.8v-39.5l-17.8,6.4L199.3,231.8z M181.1,197.4l17.6-6.4
                l-23.4-22.5L181.1,197.4z"></path>
              <polygon fill="var(--alpha)" points="224.8,168.1 219,197.4 201.2,191"></polygon>
              <polygon fill="var(--alpha)" points="218.5,198.7 200.6,231.8 200.6,192.3"></polygon>
              <polygon fill="var(--alpha)" points="199.3,192.3 199.3,231.8 181.5,198.7"></polygon>
              <polygon fill="var(--alpha)" points="198.7,191 181.1,197.4 175.3,168.5"></polygon>
            </g>
          </g>
        </g>
        </g>
      <g id="middle">
        <path ref={refOuterRing} fill="url(#strategyGradient)" d="M350,200h9.5c0,88.1-71.4,159.5-159.5,159.5c-88.1,0-159.5-71.4-159.5-159.5c0-88.1,71.4-159.5,159.5-159.5
          c88.1,0,159.5,71.4,159.5,159.5H350h-9.5C340.4,122.4,277.6,59.6,200,59.5C122.4,59.6,59.6,122.4,59.5,200
          c0.1,77.6,62.9,140.4,140.5,140.5c77.6-0.1,140.4-62.9,140.5-140.5H350z"></path>
        <circle ref={refDottedRing} fill="none" stroke="var(--bravo)" strokeWidth="6" strokeMiterlimit="10" strokeDasharray="1.999,3.98" cx="200" cy="200" r="120"></circle>
        <circle ref={refInnerRing} fill="none" stroke="var(--alpha)" strokeWidth="3" strokeLinecap="round" strokeMiterlimit="10" cx="200" cy="200" r="130"></circle>
      </g>
    </svg>
  )
}

export default Strategy